$main: #103366;

@mixin transition($time, $prop) {
  -webkit-transition: $time+s $prop;
  -moz-transition: $time+s $prop;
  -ms-transition: $time+s $prop;
  -o-transition: $time+s $prop;
  transition: $time+s $prop;
}

@font-face {
  font-family: 'noto';
  src: url("../fonts/NotoSans-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

html, body {
  font-family: noto, sans-serif;
}

.vinOrPlate {
  margin-top: 60px;

  > p {
    max-width: 313px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    color: $main;
    line-height: 24px;
    margin-bottom: 37px;
  }

  .chooseGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    justify-content: center;

    .identifyItem {
      text-align: center;
    }

    .carPlate {
      button.active {
        background-image: url("https://pwa.apps.am/old/img/car-plate-bg.svg");
        background-repeat: no-repeat;
        background-position: center 14px;
      }
    }

    button {
      background: none;
      border: none;
      text-align: center;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      background: #F6F7F8;
      width: 100%;
      padding: 39px 13px 20px;
      font-size: 14px;

      &.active {
        background: #FFF;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 1px 5px 0 rgba(16, 51, 102, 0.10);
        backdrop-filter: blur(10px);
        border: 2px solid #00CCFF;
      }

      img {
        display: block;
        width: 50px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 23px;
      }

      span {
        display: block;
        line-height: 24px;
        color: $main;
      }
    }
  }

  .carFinderTypes {
    margin-top: 25px;
  }

  .carInputs {
    position: relative;

    label {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 4px;
    }

    input {
      border-radius: 1px;
      border: 1px solid #A6AEBD;
      background: #FFF;
      display: block;
      width: 100%;
      padding: 4px;
    }
  }

  .plateNumberInputs {
    input {
      padding-right: 39px;
    }

    a {
      position: absolute;
      bottom: 7px;
      right: 10px;
    }
  }

  .vinCodeInputs {
    display: none;
  }
}

.identifierWrap,
.notIdentifierWrap {

  .isIdentifier {
    text-align: center;

    > img {
      width: 20px;
      display: inline-block;
      margin-bottom: 8px;
    }

    .chooseGrid {
      margin-top: 36px;

      div {
        background: none;
        border: none;
        text-align: center;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        background: #fff;
        width: 100%;
        padding: 39px 13px 20px;
        font-size: 14px;
        -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 1px 5px 0 rgba(16, 51, 102, 0.10);
        -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 1px 5px 0 rgba(16, 51, 102, 0.10);
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 1px 5px 0 rgba(16, 51, 102, 0.10);
        backdrop-filter: blur(10px);

        img {
          display: block;
          width: 137px;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 23px;
        }

        span {
          display: block;
          line-height: 24px;
          color: $main;
        }
      }
    }

  }

  .vehicleList {
    margin-top: 38px;

    ul {
      text-align: left;
      list-style-type: none;
      padding-left: 0;
      margin-bottom: 0;

      li {
        margin-bottom: 14px;
      }
    }

    input[type="radio"] {
      display: none;

      &:checked + label {
        border: 1px solid #0CF;
      }
    }

    label {
      display: block;
      padding: 7px 17px;
      border-radius: 1px;
      border: 1px solid #DBDFE5;
      background: #FFF;
      @include transition(0.1, all);
      cursor: pointer;

      img {
        margin-right: 8px;
      }
    }
  }
}


.accidentWrap {

  .vehicleImpact {
    > p {
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: $main;
    }
  }

  .vehicleConstruction {
    position: relative;
    max-width: 368px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    img {
      width: 100%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    input[type="checkbox"] {
      display: none;

      & + label {
        position: absolute;
        width: 35px;
        height: 35px;
        background-image: url('https://pwa.apps.am/old/img/tag.svg');
        -webkit-background-size: cover;
        background-size: cover;
      }
    }

    input[type="checkbox"]:checked {
      & + label {
        background-image: url('https://pwa.apps.am/old/img/tag-active.svg');
      }
    }

    label[data-part="leftFrontFender"] {
      left: 21.1%;
      top: 12%;
    }

    label[data-part="frontBumper"] {
      left: 43.5%;
      top: 8.2%;
    }

    label[data-part="rightFrontFender"] {
      left: 65.5%;
      top: 12%;;
    }

    label[data-part="leftFrontDoor"] {
      left: 17.3%;
      top: 25.2%;
    }

    label[data-part="hood"] {
      left: 43.5%;
      top: 23.3%;
    }

    label[data-part="rightFrontDoor"] {
      left: 69.7%;
      top: 25.2%;
    }

    label[data-part="leftRearDoor"] {
      left: 17.6%;
      top: 51.9%;
    }

    label[data-part="roof"] {
      left: 43.5%;
      top: 46%;
    }

    label[data-part="rightRearDoor"] {
      left: 69.2%;
      top: 51.9%;
    }

    label[data-part="leftRearFender"] {
      left: 18.4%;
      top: 72.6%;
    }

    label[data-part="rearBumper"] {
      left: 43.5%;
      top: 77%;
    }

    label[data-part="rightRearFender"] {
      left: 67.8%;
      top: 72.6%;
    }

  }
}

.documentPhotoWrap {
  .uploadDocuments {
    > p {
      max-width: 158px;
      margin-left: auto;
      margin-right: auto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: $main;
    }

    .selectDocuments {
      margin-top: 39px;
      position: relative;
      //button {
      max-width: 298px;
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      background-color: #fff;
      padding-top: 19px;
      min-height: 245px;
      border: none;
      -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 1px 5px 0 rgba(16, 51, 102, 0.10);
      -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 1px 5px 0 rgba(16, 51, 102, 0.10);
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 1px 5px 0 rgba(16, 51, 102, 0.10);
      backdrop-filter: blur(10px);
      margin-bottom: 100px;

      img {
        display: block;
        width: 96px;
        margin-left: auto;
        margin-right: auto;
        pointer-events: none;
      }

      span {
        margin-top: 4px;
        display: block;
        max-width: 208px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: #A6AEBD;
        pointer-events: none;
      }

      //}
    }
  }
}

.uploadedPhotoWrap {
  .addMoreDocuments {
    input[type='file'] {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;

      + label {
        display: block;
        margin-top: 0;
        cursor: pointer;
        margin-bottom: 0;
        -webkit-border-radius: 1px;
        -moz-border-radius: 1px;
        border-radius: 1px;
        border: 1px solid $main;
        background: #FFF;
        padding: 4px 8px;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: $main;

        img {
          display: inline-block;
          margin-right: 5px;
          width: 18px;
        }
      }
    }
  }

  .uploadDocuments {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-bottom: 18px;

    > p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: $main;
      margin-bottom: 0;
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      bottom: 0;
      background-color: #DBDFE5;
    }

    button {
      -webkit-border-radius: 1px;
      -moz-border-radius: 1px;
      border-radius: 1px;
      border: 1px solid $main;
      background: #FFF;
      padding: 4px 8px;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      height: 33px; // todo need to check
      color: $main;

      img {
        display: inline-block;
        margin-right: 10px;
        width: 18px;
        margin-top: -4px;
      }
    }
  }

  .uploadedGallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5px;

    .oneDocument {
      background-color: #F6F7F8;

      position: relative;

      p {
        margin-bottom: 0;
        position: relative;
        padding: 12px 35px 12px 10px;

      }

      span {
        position: relative;
        display: block;
        background-color: #F6F7F8;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: $main;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 187px;
        width: 100%;
      }

      button {
        position: absolute;
        right: 8px;
        top: 8px;
        width: 22px;
        //height: 20px;
        background: none;
        border: none;
        padding: 5px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;

        img {
          width: 100%;
          height: auto;
        }
      }

      img {
        display: block;
        width: 100%;
        height: 117px;
        object-fit: cover;
      }

      svg {
        max-width: 40%;
        width: 100%;
        max-height: 40%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        display: block;
      }
    }
  }
}

.carCameraWrap {
  padding: 0;

  .camera {
    width: calc(100% - 132px);
    //background: url("../img/peguot.png");
    -webkit-background-size: cover;
    background-size: cover;
    height: calc(100% + 1px);
    position: relative;

    .goBack {
      position: absolute;
      left: 44px;
      top: 40px;
      color: $main;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      text-decoration: none;
      border: none;
      padding-right: 15px;

      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      background: rgba(#FFF, 0.6);

      img {
        width: 24px;
      }
    }

    .cameraArrow {
      position: absolute;
      right: 42px;
      bottom: 28px;
      width: 87px;
      height: 87px;

      button {
        position: absolute;
        border: none;
        background: none;
        padding: 0;
        display: block;

        &[data-type="up"] {
          top: 0;
          left: 50%;
          -webkit-transform: translateX(-50%);
          -moz-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          -o-transform: translateX(-50%);
          transform: translateX(-50%);
        }

        &[data-type="down"] {
          bottom: 0;
          left: 50%;
          -webkit-transform: translateX(-50%);
          -moz-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          -o-transform: translateX(-50%);
          transform: translateX(-50%);
        }

        &[data-type="right"] {
          top: 50%;
          right: 0;
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          transform: translateY(-50%);
        }

        &[data-type="left"] {
          top: 50%;
          left: 0;
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          transform: translateY(-50%);
        }
      }
    }

    .cameraBorders {
      position: absolute;
      width: calc(100% - 12px);
      height: calc(100% - 12px);
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      pointer-events: none;

      span {
        opacity: 0.7;

        &[data-side = "up"] {
          height: 10px;
          background-color: #fff;
          position: absolute;
          top: 0;
          left: 10px;
          width: calc(100% - 20px);
        }

        &[data-side = "right"] {
          height: 100%;
          background-color: red;
          position: absolute;
          top: 0;
          right: 0;
          width: 10px;
        }

        &[data-side = "down"] {
          height: 10px;
          background-color: #fff;
          position: absolute;
          bottom: 0;
          left: 10px;
          width: calc(100% - 20px);
        }

        &[data-side = "left"] {
          height: 100%;
          background-color: #fff;
          position: absolute;
          top: 0;
          left: 0;
          width: 10px;
        }
      }
    }

    .attention {
      position: absolute;
      max-width: 364px;
      width: 100%;
      background-color: rgba(#fff, 0.9);
      padding: 12px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      transform: translateX(-50%);
      top: 24px;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: #F55722;

      img {
        width: 18px;
        -webkit-transform: translateY(-1px);
        -moz-transform: translateY(-1px);
        -ms-transform: translateY(-1px);
        -o-transform: translateY(-1px);
        transform: translateY(-1px);
        margin-right: 6px;
      }
    }
  }

  max-width: none;
  height: 100vh;
  position: relative;

  .cameraOptions {
    display: flex;
    height: 100%;
  }

  .optionItems {
    width: 132px;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    button {
      background: none;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      line-height: 60px;

      &.flashBtn {
        img {
          width: 27px;
        }
      }

      img {
        width: 20px;
      }
    }

    .shootOption {
      button {
        background-color: $main;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          width: 68px;
          height: 68px;
          background-color: transparent;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          left: -4px;
          top: -4px;
          border: 1px solid #A6AEBD;
          pointer-events: none;
        }

      }
    }

    .imagesOption {
      button {
        position: relative;

        &::before {
          content: attr(data-count);
          position: absolute;
          width: 16px;
          height: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #FFD100;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          right: -6px;
          top: -2px;
          z-index: 2;
        }

        img {
          width: 60px;
          height: 60px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          object-fit: cover;
        }

        &::after {
          content: '';
          position: absolute;
          width: 68px;
          height: 68px;
          background-color: transparent;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          left: -4px;
          top: -4px;
          border: 1px solid #A6AEBD;
          pointer-events: none;
        }

      }
    }
  }
}

.carSides {
  overflow: auto;
  width: 110px;
  display: grid;
  grid-template-columns: 1fr;
  right: 30px;
  top: 36px;
  z-index: 5;
  margin-top: 8px;
  padding-top: 10px;
  padding-right: 8px;
  margin-bottom: 18px;

  > div {
    background-color: #fff;
    padding: 5px;
    //-webkit-border-radius: 4px;
    //-moz-border-radius: 4px;
    //border-radius: 4px;
    border: 1px solid rgba(#A6AEBD, 0.5);
    border-bottom: none;
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -o-transform: translateY(-5px);
    transform: translateY(-5px);

    //@for $i from 1 through 12 {
    //  $a: 2*$i;
    //  &:nth-child(#{$i}) {
    //    -webkit-transform: translateY(-#{$a}px);
    //    -moz-transform: translateY(-#{$a}px);
    //    -ms-transform: translateY(-#{$a}px);
    //    -o-transform: translateY(-#{$a}px);
    //    transform: translateY(-#{$a}px);
    //  }
    //}

    &:last-of-type {
      border-bottom: 1px solid rgba(#A6AEBD, 0.5);
    }


    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      border: 2px solid transparent;
      left: 0;
      top: 0;
      z-index: 2;
      pointer-events: none;
      //-webkit-border-radius: 4px;
      //-moz-border-radius: 4px;
      //border-radius: 4px;
    }

    &.imgExist {
      img,
      span {
        opacity: 1;
        color: #40A448;
      }

      &::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        background-image: url('https://pwa.apps.am/old/img/img-exist-check.png');
        right: -7px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        z-index: 4;
        -webkit-background-size: cover;
        background-size: cover;
      }
    }

    &.active {
      position: relative;
      z-index: 2;
      //-webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 1px 5px 0 rgba(16, 51, 102, 0.10);
      //-moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 1px 5px 0 rgba(16, 51, 102, 0.10);
      //box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 1px 5px 0 rgba(16, 51, 102, 0.10);

      &::before {
        border-color: #0CF;
      }

      img, span {
        opacity: 1;
      }
    }
  }

  img {
    display: block;
    height: 15px;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4px;
    opacity: 0.5;
    position: relative;
    z-index: 5;
  }

  span {
    display: block;
    text-align: center;
    color: $main;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    opacity: 0.5;
    position: relative;
    z-index: 5;
  }
}

.identifierButton {
  .fileUploaderButton {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    + label {
      border: none;
      -webkit-border-radius: 1px;
      -moz-border-radius: 1px;
      border-radius: 1px;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      padding: 4px 16px;
      background: none;
      background-color: $main;
      color: #fff;
      cursor: pointer;
      margin-bottom: 0;

      &[data-disabled] {
        opacity: 0.7;
      }
    }
  }
}

.selectDocuments {
  .fileUploaderButton {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    + label {
      display: block;
      margin-top: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      cursor: pointer;
      margin-bottom: 0;
    }
  }
}

.orientationFix {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: $main;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  opacity: 0.9;
  text-align: center;

  img {
    width: 100px;
    margin-bottom: 20px;
  }

  .wrapperActions {
    .wrapperAction {
      border: none;
      text-align: center;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      background-color: blue;
      color: white;
      width: 100%;
      padding: 5px 10px;
      font-size: 14px;

      &:hover {
        background-color: white;
        color: black;
      }
    }
  }
}

@keyframes anim-rotate {
  0%, 10% {
    transform: rotate(0deg)
  }
  40%, 60% {
    transform: rotate(-90deg)
  }
  95%, 100% {
    transform: rotate(0deg)
  }
}


.keepDistance {
  width: 424px;
  height: 80px;
  background-image: url("https://pwa.apps.am/old/img/arrow-background.png");
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  background-size: contain;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .closer1 {
    width: 131px;
    -webkit-transform: translateY(18px);
    -moz-transform: translateY(18px);
    -ms-transform: translateY(18px);
    -o-transform: translateY(18px);
    transform: translateY(18px);
    animation: ani3 1.0s steps(5, start) infinite;
    -webkit-animation: ani3 1.0s steps(5, start) infinite;
  }

  .closer2 {
    width: 209px;
    -webkit-transform: translateY(12px);
    -moz-transform: translateY(12px);
    -ms-transform: translateY(12px);
    -o-transform: translateY(12px);
    transform: translateY(12px);
    animation: ani2 1.0s steps(5, start) infinite;
    -webkit-animation: ani2 1.0s steps(5, start) infinite;
  }

  .closer3 {
    width: 297px;
    animation: ani1 1.0s steps(5, start) infinite;
    -webkit-animation: ani1 1.0s steps(5, start) infinite;
  }

  &.farDistance {
    > div {
      -webkit-transform: translateY(10px) rotate(180deg);
      -moz-transform: translateY(10px) rotate(180deg);
      -ms-transform: translateY(10px) rotate(180deg);
      -o-transform: translateY(10px) rotate(180deg);
      transform: translateY(10px) rotate(180deg);
    }
  }

  &.moveRight,
  &.moveLeft {
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      display: flex;

      &:first-of-type {
        margin-right: 35px;
      }

      &:last-of-type {
        margin-left: 35px;
      }

      .moveToLeft1 {
        animation: ani3 1.0s steps(5, start) infinite;
        -webkit-animation: ani3 1.0s steps(5, start) infinite;
      }

      .moveToLeft2 {
        animation: ani2 1.0s steps(5, start) infinite;
        -webkit-animation: ani2 1.0s steps(5, start) infinite;
      }

      .moveToLeft3 {
        animation: ani1 1.0s steps(5, start) infinite;
        -webkit-animation: ani1 1.0s steps(5, start) infinite;
      }

      .moveToRight1 {
        animation: ani3 1.0s steps(5, start) infinite;
        -webkit-animation: ani3 1.0s steps(5, start) infinite;
      }

      .moveToRight2 {
        animation: ani2 1.0s steps(5, start) infinite;
        -webkit-animation: ani2 1.0s steps(5, start) infinite;
      }

      .moveToRight3 {
        animation: ani1 1.0s steps(5, start) infinite;
        -webkit-animation: ani1 1.0s steps(5, start) infinite;
      }

      img {
        height: 50px;
      }
    }
  }
}


@keyframes ani1 {
  0% {
    opacity: 0.1;
  }
  11% {
    opacity: 0.1;
  }
  22% {
    opacity: 0.1;
  }
  33% {
    opacity: 0.15;
  }
  44% {
    opacity: 0.25;
  }
  55% {
    opacity: 0.5;
  }
  66% {
    opacity: 1;
  }
  77% {
    opacity: 0.5;
  }
  88% {
    opacity: 0.25;
  }
  100% {
    opacity: 0.15;
  }
}

@keyframes ani2 {
  0% {
    opacity: 0.1;
  }
  11% {
    opacity: 0.15;
  }
  22% {
    opacity: 0.25;
  }
  33% {
    opacity: 0.5;
  }
  44% {
    opacity: 1;
  }
  55% {
    opacity: 0.5;
  }
  66% {
    opacity: 0.25;
  }
  77% {
    opacity: 0.15;
  }
  88% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.1;
  }
}

@keyframes ani3 {
  0% {
    opacity: 0.25;
  }
  11% {
    opacity: 0.5;
  }
  22% {
    opacity: 1;
  }
  33% {
    opacity: 0.5;
  }
  44% {
    opacity: 0.25;
  }
  55% {
    opacity: 0.15;
  }
  66% {
    opacity: 0.1;
  }
  77% {
    opacity: 0.1;
  }
  88% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.15;
  }
}


// custom

.videoBorders {
  position: absolute;
  pointer-events: none;
  left: 20px;
  top: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);

  > span {
    width: 37px;
    height: 37px;
    border-color: rgba(#fff, 0.5);
    border-width: 4px;
    position: absolute;
    -webkit-border-radius: 0 5px 0 0;
    -moz-border-radius: 0 5px 0 0;
    border-radius: 0 5px 0 0;

  }

  .leftTop {
    top: 0;
    left: 0;
    border-left-style: solid;
    border-top-style: solid;
    -webkit-border-radius: 5px 0 0 0;
    -moz-border-radius: 5px 0 0 0;
    border-radius: 5px 0 0 0;

    -webkit-animation: itemFocus1 5s ease-in infinite;
    -o-animation: itemFocus1 5s ease-in infinite;
    animation: itemFocus1 5s ease-in infinite;
  }

  .rightTop {
    right: 0;
    top: 0;
    border-right-style: solid;
    border-top-style: solid;
    -webkit-border-radius: 0 5px 0 0;
    -moz-border-radius: 0 5px 0 0;
    border-radius: 0 5px 0 0;

    -webkit-animation: itemFocus2 5s ease-in infinite;
    -o-animation: itemFocus2 5s ease-in infinite;
    animation: itemFocus2 5s ease-in infinite;

  }

  .leftBottom {
    left: 0;
    bottom: 0;
    border-left-style: solid;
    border-bottom-style: solid;
    -webkit-border-radius: 0 0 0 5px;
    -moz-border-radius: 0 0 0 5px;
    border-radius: 0 0 0 5px;

    -webkit-animation: itemFocus3 5s ease-in infinite;
    -o-animation: itemFocus3 5s ease-in infinite;
    animation: itemFocus3 5s ease-in infinite;
  }

  .rightBottom {
    right: 0;
    bottom: 0;
    border-right-style: solid;
    border-bottom-style: solid;
    -webkit-border-radius: 0 0 5px 0;
    -moz-border-radius: 0 0 5px 0;
    border-radius: 0 0 5px 0;

    -webkit-animation: itemFocus4 5s ease-in infinite;
    -o-animation: itemFocus4 5s ease-in infinite;
    animation: itemFocus4 5s ease-in infinite;
  }
}

@keyframes itemFocus1 {

  0%, 10%, 20%, 100% {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    border-color: rgba(#fff, 0.5);
  }
  30%, 40%, 50%, 60% {
    -webkit-transform: translate(100px, 50px);
    -moz-transform: translate(100px, 50px);
    -ms-transform: translate(100px, 50px);
    -o-transform: translate(100px, 50px);
    transform: translate(100px, 50px);
    border-color: #fff;
  }
  70%, 80%, 90% {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    border-color: rgba(#fff, 0.5);
  }
}

@keyframes itemFocus2 {

  0%, 10%, 20%, 100% {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    border-color: rgba(#fff, 0.5);
  }
  30%, 40%, 50%, 60% {
    -webkit-transform: translate(-100px, 50px);
    -moz-transform: translate(-100px, 50px);
    -ms-transform: translate(-100px, 50px);
    -o-transform: translate(-100px, 50px);
    transform: translate(-100px, 50px);
    border-color: #fff;
  }
  70%, 80%, 90% {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    border-color: rgba(#fff, 0.5);
  }
}

@keyframes itemFocus3 {
  0%, 10%, 20%, 100% {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    border-color: rgba(#fff, 0.5);
  }
  30%, 40%, 50%, 60% {
    -webkit-transform: translate(100px, -50px);
    -moz-transform: translate(100px, -50px);
    -ms-transform: translate(100px, -50px);
    -o-transform: translate(100px, -50px);
    transform: translate(100px, -50px);
    border-color: #fff;
  }
  70%, 80%, 90% {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    border-color: rgba(#fff, 0.5);
  }
}

@keyframes itemFocus4 {

  0%, 10%, 20%, 100% {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    border-color: rgba(#fff, 0.5);
  }
  30%, 40%, 50%, 60% {
    -webkit-transform: translate(-100px, -50px);
    -moz-transform: translate(-100px, -50px);
    -ms-transform: translate(-100px, -50px);
    -o-transform: translate(-100px, -50px);
    transform: translate(-100px, -50px);
    border-color: #fff;
  }
  70%, 80%, 90% {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    border-color: rgba(#fff, 0.5);
  }
}


.imageCollection {
  background-color: rgba(#000, 0.5);

  .modal-body {
    text-align: center;
    padding: 40px 40px 0;

    img {
      width: 37px;
      display: inline-block;
      margin-bottom: 20px;
    }

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: $main;
      margin-bottom: 10px;
    }

    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: #A6AEBD;
      display: inline-block;
      margin-bottom: 40px;
    }
  }

  .modal-footer {
    border-top: none;
    padding: 0 40px 40px;

    button {
      border: none;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      padding: 4px 16px;
      display: inline-block;
    }

    .cancel {
      background-color: #fff;
      color: $main;
    }

    .nextStep {
      background-color: $main;
      color: #fff;
    }
  }
}

.imageCollection {
  display: none !important;
}

.photographPosition {
  position: absolute;
  left: 27px;
  bottom: 36px;
  width: 90px;

  .ellipseBig {
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%) scale(0.5);
    -moz-transform: translateX(-50%) scale(0.5);
    -ms-transform: translateX(-50%) scale(0.5);
    -o-transform: translateX(-50%) scale(0.5);
    transform: translateX(-50%) scale(0.5);
    animation: ani1 1.0s steps(5, start) infinite;
    -webkit-animation: ani1 1.0s steps(5, start) infinite;
  }

  .ellipseSmall {
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    animation: ani2 1.0s steps(5, start) infinite;
    -webkit-animation: ani2 1.0s steps(5, start) infinite;
  }

  .cameraPosition {
    position: absolute;
    bottom: 10px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}