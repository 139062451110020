@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,600;0,700&display=swap');

$main: #103366;

html,
body {
  font-family: 'noto', sans-serif !important;
  //min-height: 100vh;
}

.mWrap {
  max-width: 767px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 55px 30px 110px;
}

.steps {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: calc(100% + 60px);
    height: 1px;
    background: #DBDFE5;
    bottom: 0;
    left: -30px;
  }
}

.nav-tabs {
  border: none;
  position: relative;
  //justify-content: center;
  overflow-y: hidden;
  overflow-x: auto;
  flex-wrap: nowrap;
  padding-top: 13px;


  .nav-link {
    padding: 12px 23px;
    -webkit-border-radius: 12px 12px 0 0;
    -moz-border-radius: 12px 12px 0 0;
    border-radius: 12px 10px 0 0;
    color: #CACED7;
    background: #fff;
    border: none;
    font-weight: 700;
    white-space: nowrap;


    &.active {
      color: $main;
      background-color: #F6F7F8;
      border: none;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: 49px;
        height: 2px;
        background-color: #00CCFF;
        left: 50%;
        bottom: 6px;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
      }
    }
  }
}

.identifierButton {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  //height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #fff;
  -webkit-box-shadow: 0 -1px 6px 0 #0000001f;;
  -moz-box-shadow: 0 -1px 6px 0 #0000001f;;
  box-shadow: 0 -1px 6px 0 #0000001f;;

  button {
    border: none;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    padding: 4px 16px;
    background: none;
  }

  .back {
    color: $main;
    margin-right: 10px;
  }

  .ident {
    background-color: $main;
    color: #fff;
  }
}

.informationText {
  position: relative;
  padding: 13px 4px 13px 60px;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.10);
  -moz-box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.10);
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.10);

  p {
    color: $main;
    margin-bottom: 0;
    position: relative;

    &::before {
      content: '';
      width: 24px;
      height: 24px;
      position: absolute;
      background-image: url('https://pwa.apps.am/old/img/info-circle.svg');
      -webkit-background-size: cover;
      background-size: cover;
      left: -40px;
      top: 0;
    }
  }

  &::before {
    content: '';
    position: absolute;
    width: 4px;
    height: calc(100% - 14px);
    left: 0;
    top: 7px;
    background-color: $main;
  }
}
button[disabled]{
  opacity: 0.7;
}
